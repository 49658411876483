<template>
  <v-card id="promotion-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          :disabled="hasNotPermission('Permissions.PaymentMethodCreate')"
          @click="createDialogShow()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="paymentMethods"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >
      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <template #[`item.rkCurrencyId`]="{item}">
        {{ item.rkCurrencyId }}
      </template>

      <template #[`item.rkReasonId`]="{item}">
        {{ item.rkReasonId }}
      </template>

      <template #[`item.description`]="{item}">
        <span class="text-no-wrap">{{ item.description }}</span>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.PaymentMethodUpdate')"
                v-bind="attrs"
                v-on="on"
                @click="updateDialogShow(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.PaymentMethodDelete')"
                v-bind="attrs"
                v-on="on"
                @click="deletePopupShow(item.id, item.name)"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <delete-dialog @delete-confirmed="deletePaymentMethod" :label="deleteLabel" ref="deletePopup" />
    <payment-method-form :label="'Редактирование способа оплаты'" :cancellation="updateDialogCancel"
                         :show="updateDialog"
                         :save="updateContact"></payment-method-form>
    <payment-method-form :label="'Создание способа оплаты'" :cancellation="createDialogCancel" :show="createDialog"
                         :save="addContact"></payment-method-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import DeleteDialog from '@/components/delete-dialog'
import PaymentMethodForm from './payment-method-form'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiFacebook,
  mdiInstagram,
  mdiWhatsapp,
  mdiPhone,
  mdiPencilOutline,
} from '@mdi/js'
import { hasPermission } from '@/helpers'

export default {
  components: { DeleteDialog, PaymentMethodForm },
  name: 'PaymentMethodList',
  data: () => ({
    createDialog: false,
    updateDialog: false,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
      mdiFacebook,
      mdiInstagram,
      mdiWhatsapp,
      mdiPhone,
    },
    deleteLabel: '',
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('paymentMethod', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('paymentMethod', {
      paymentMethods: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Идентификатор валюты', value: 'rkCurrencyId' },
        { text: 'Наименование', value: 'name' },
        { text: 'Причина', value: 'rkReasonId' },
        { text: 'Описание', value: 'description' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Способы оплаты'
    this.fetchPaymentMethods()
  },
  methods: {
    ...mapActions('paymentMethod', {
      fetchPaymentMethods: 'fetchList',
      deletePaymentMethod: 'delete',
    }),
    ...mapActions('paymentMethod/form', ['create', 'resetForm', 'fetchById', 'update']),
    hasNotPermission(val) {
      return !hasPermission(val)
    },
    deletePopupShow(id, name) {
      this.deleteLabel = 'Спосою оплаты ' + name + ' будет удален.'
      this.$refs.deletePopup.open(id)
    },
    async addContact() {
      await this.create()
      this.createDialog = false
    },
    async updateContact() {
      await this.update()
      this.updateDialog = false
    },
    createDialogShow() {
      this.createDialog = true
    },
    createDialogCancel() {
      this.createDialog = false
    },
    updateDialogCancel() {
      this.updateDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
