<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="name"
                label="Наименование"
                dense
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="rkCurrencyId"
                :items="currencyDropDownId"
                :menu-props="{ maxHeight: '400' }"
                label="Валюта"
                dense
                :rules="[rules.required]"
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="type"
                :items="paymentMethodTypesDropDown"
                :menu-props="{ maxHeight: '400' }"
                label="Тип"
                dense
                :rules="[rules.required]"
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="rkReasonId"
                :items="reasonsDropDown"
                :menu-props="{ maxHeight: '400' }"
                label="Причина"
                dense
                :rules="[rules.required]"
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-textarea
                v-model="description"
                label="Описание"
                dense
                outlined
              ></v-textarea>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-textarea
                v-model="icon"
                label="Иконка"
                dense
                outlined
              ></v-textarea>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-checkbox
                v-model="isCashRequired"
                label="Обязателен ввод суммы"
                dense
                outlined
              ></v-checkbox>
            </v-col>

            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/helpers/validation'

export default {
  name: 'PaymentMethodForm',
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('paymentMethod/form', [
      'rkCurrencyId',
      'name',
      'type',
      'rkReasonId',
      'isCashRequired',
      'description',
      'icon',
    ]),
    ...mapGetters('dictionary', { reasonsDropDown: 'rk7ReasonsDropDown' }),
    ...mapGetters('dictionary', { currencyDropDownId: 'currencyDropDownId' }),
    ...mapGetters('dictionary', { paymentMethodTypesDropDown: 'paymentMethodTypesDropDown' }),
  },
  async mounted() {
    await this.fetchCurrencies()
    await this.fetchReasons()
    await this.fetchPaymentMethodTypes()
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('dictionary', {
      fetchReasons: 'fetchRk7Reasons',
      fetchCurrencies: 'fetchCurrencies',
      fetchPaymentMethodTypes: 'fetchPaymentMethodTypes',
    }),
    ...mapActions('paymentMethod/form', ['resetForm']),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
